var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-tab-overview"},[_c('v-card',{staticClass:"mb-7"},[_c('v-card-title',{directives:[{name:"t",rawName:"v-t",value:('jobList'),expression:"'jobList'"}]}),_c('v-data-table',{attrs:{"headers":_vm.tableColumnHeaders,"items":_vm.jobs,"hide-default-footer":""},scopedSlots:_vm._u([{key:"header.title",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.candidatesAmount",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.stage",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('router-link',{staticClass:"font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name: 'apps-ats-job-view', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(((item.city) + ", " + (item.country))))])],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }