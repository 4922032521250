<template>
  <div id="user-view">
    <v-row>
      <v-col v-if="userData.id" cols="12" md="5" lg="4">
        <bread-crumbs
          :context="{
            recruiterName: `${userData.user.firstName} ${userData.user.lastName}`,
          }"
        />
        <user-bio-panel :user-data="userData" @refetch-user-data="fetchUserData"></user-bio-panel>
      </v-col>

      <v-col cols="12" md="7" lg="8">
        <v-tabs v-model="userTab" show-arrows class="user-tabs">
          <v-tab v-for="tab in tabs" :key="tab.icon">
            <v-icon size="20" class="me-3">
              {{ tab.icon }}
            </v-icon>
            <span>{{ $t(tab.title) }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items id="user-tabs-content" v-model="userTab" class="mt-5 pa-1" touchless>
          <v-tab-item>
            <user-tab-overview v-if="userData.id" :jobs-data="userData.jobs"></user-tab-overview>
          </v-tab-item>

          <v-tab-item>
            <user-tab-security></user-tab-security>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { mdiAccountOutline, mdiLockOutline } from '@mdi/js'
import { useActions } from 'vuex-composition-helpers'
import router from '@/router'

// eslint-disable-next-line object-curly-newline
import UserBioPanel from './user-bio-panel/UserBioPanel.vue'
import UserTabOverview from './user-tab-overview/UserTabOverview.vue'
import UserTabSecurity from './user-tab-security/UserTabSecurity.vue'
import BreadCrumbs from '@/components/files-table/BreadCrumbs.vue'

export default {
  components: {
    BreadCrumbs,
    UserBioPanel,
    UserTabOverview,
    UserTabSecurity,
  },
  setup() {
    const userData = ref({})
    const userTab = ref(null)

    const { getRecruiterData } = useActions('company', ['getRecruiterData'])

    const fetchUserData = async () => {
      const { data, success } = await getRecruiterData({ id: router.currentRoute.params.id })
      if (success) userData.value = data
    }

    onMounted(async () => {
      await fetchUserData()
    })

    const tabs = [
      { icon: mdiAccountOutline, title: 'overview' },
      { icon: mdiLockOutline, title: 'security' },
    ]

    return {
      tabs,
      userTab,
      userData,

      fetchUserData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
