<template>
  <!-- edit profile dialog -->
  <v-dialog v-model="isBioDialogOpen" max-width="650px" @click:outside="$emit('update:is-bio-dialog-open', false)">
    <v-card class="user-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5"> Edit User Information </v-card-title>

      <v-card-text class="mt-5">
        <v-form ref="bioEditForm" v-model="valid" class="multi-col-validation" @submit.prevent="handleFormSubmit">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="userDataLocal.user.firstName"
                outlined
                dense
                maxlength="150"
                :label="$t('firstName', { postfix: '*' })"
                :placeholder="$t('firstName', { postfix: '*' })"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="userDataLocal.user.lastName"
                outlined
                dense
                maxlength="150"
                :label="$t('lastName', { postfix: '*' })"
                :placeholder="$t('lastName', { postfix: '*' })"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="userDataLocal.access"
                :items="accessOptions"
                outlined
                dense
                :label="$t('access')"
                :placeholder="$t('access')"
                :rules="[validators.required]"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="userDataLocal.user.phone"
                outlined
                dense
                :label="$t('phoneNumber')"
                :placeholder="$t('phoneNumber')"
                maxlength="31"
              ></v-text-field>
            </v-col>

            <v-col cols="12" class="d-flex justify-center mt-3">
              <v-btn
                v-t="'discard'"
                outlined
                color="secondary"
                class="me-3"
                @click.prevent="$emit('update:is-bio-dialog-open', false)"
              />
              <v-btn color="primary" type="submit" :loading="loading" :disabled="!valid || !areDataChanged">
                {{ $t('save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch, computed, getCurrentInstance } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'
import { required } from '@core/utils/validation'
import countries from '@/@fake-db/data/other/countries'

export default {
  props: {
    isBioDialogOpen: {
      type: Boolean,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy
    const valid = ref(true)
    const loading = ref(false)
    const bioEditForm = ref(null)
    const userDataLocal = ref({})
    userDataLocal.value = JSON.parse(JSON.stringify(props.userData))
    const { updateRecruiter } = useActions('company', ['updateRecruiter'])

    // on form submit
    const handleFormSubmit = async () => {
      const isFormValid = bioEditForm.value.validate()
      if (!isFormValid) return
      const { id, ...payload } = userDataLocal.value
      loading.value = true
      const { success } = await updateRecruiter({ id, payload })
      loading.value = false
      if (success) {
        emit('update:is-bio-dialog-open', false)
        emit('refetch-user-data')
      }
    }

    const accessOptions = [
      {
        value: 'admin',
        text: vm.$t('admin'),
      },
      {
        value: 'recruiter',
        text: vm.$t('recruiter'),
      },
      {
        value: 'expert',
        text: vm.$t('expert'),
      },
      {
        value: 'suspended',
        text: vm.$t('suspended'),
      },
    ]

    watch(
      () => props.isBioDialogOpen,
      () => {
        userDataLocal.value = JSON.parse(JSON.stringify(props.userData))
      },
    )

    const areDataChanged = computed(() => {
      return (
        userDataLocal.value.access !== props.userData.access ||
        userDataLocal.value.user.firstName !== props.userData.user.firstName ||
        userDataLocal.value.user.lastName !== props.userData.user.lastName ||
        userDataLocal.value.user.phone !== props.userData.user.phone
      )
    })

    return {
      valid,
      loading,
      bioEditForm,
      countries,
      userDataLocal,
      accessOptions,
      areDataChanged,
      validators: {
        required,
      },

      handleFormSubmit,
    }
  },
}
</script>
