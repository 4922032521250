<template>
  <v-row class="user-bio-panel">
    <!-- user profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <v-avatar
            :color="userData.avatar ? '' : 'primary'"
            :class="userData.avatar ? '' : 'v-avatar-light-bg primary--text'"
            size="120"
            rounded
            class="mb-4"
          >
            <v-img v-if="userData.avatar" :src="require(`@/assets/images/avatars/${userData.avatar}`)"></v-img>
            <span v-else class="font-weight-semibold text-5xl">{{
              avatarText(`${userData.user.firstName} ${userData.user.lastName}`)
            }}</span>
          </v-avatar>

          <span class="mb-2">{{ userData.user.firstName }} {{ userData.user.lastName }}</span>

          <v-chip
            label
            small
            :color="resolveUserAccessVariant(userData.access)"
            :class="`v-chip-light-bg text-sm font-weight-semibold ${resolveUserAccessVariant(
              userData.access,
            )}--text text-capitalize`"
          >
            {{ $t(userData.access) }}
          </v-chip>
        </v-card-title>

        <v-card-text class="d-flex justify-center flex-wrap mt-2 pe-sm-0">
          <div class="d-flex align-center me-8 mb-4">
            <v-avatar size="40" rounded color="primary" class="v-avatar-light-bg primary--text me-3">
              <v-icon color="primary" size="22">
                {{ icons.mdiCheck }}
              </v-icon>
            </v-avatar>

            <div>
              <h3 class="text-xl font-weight-medium mb-n1">
                {{ kFormatter(userData.totalFinishedJobs) }}
              </h3>
              <span v-t="'jobsFinished'" />
            </div>
          </div>

          <div class="d-flex align-center mb-4 me-4">
            <v-avatar size="40" rounded color="primary" class="v-avatar-light-bg primary--text me-3">
              <v-icon color="primary" size="22">
                {{ icons.mdiBriefcaseVariantOutline }}
              </v-icon>
            </v-avatar>

            <div>
              <h3 class="text-xl font-weight-medium mb-n1">
                {{ kFormatter(userData.totalOngoingJobs) }}
              </h3>
              <span v-t="'jobsOngoing'" />
            </div>
          </div>
        </v-card-text>

        <v-card-text>
          <h2 v-t="'details'" class="text-xl font-weight-semibold mb-2" />

          <v-divider></v-divider>

          <v-list>
            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('firstName', { postfix: ':' }) }}</span>
              <span class="text--secondary">{{ userData.user.firstName }}</span>
            </v-list-item>
            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('lastName', { postfix: ':' }) }}</span>
              <span class="text--secondary">{{ userData.user.lastName }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium text-no-wrap me-2">{{ $t('email', { postfix: ':' }) }}</span>
              <span class="text--secondary">{{ userData.user.email }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('status', { postfix: ':' }) }}</span>
              <span class="text--secondary">
                <v-chip
                  small
                  label
                  :color="resolveUserStatusVariant(userData.status)"
                  :class="`v-chip-light-bg ${resolveUserStatusVariant(
                    userData.status,
                  )}--text font-weight-medium text-capitalize`"
                >
                  {{ $t(userData.status ? 'active' : 'pending') }}
                </v-chip>
              </span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('role', { postfix: ':' }) }}</span>
              <span class="text--secondary text-capitalize">{{ userData.access }}</span>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn v-t="'edit'" color="primary" @click="isBioDialogOpen = !isBioDialogOpen" />
        </v-card-actions>
      </v-card>

      <!-- edit profile dialog data -->
      <user-bio-edit
        :is-bio-dialog-open.sync="isBioDialogOpen"
        :user-data="userData"
        @refetch-user-data="emit('refetch-user-data')"
      ></user-bio-edit>
    </v-col>
  </v-row>
</template>

<script>
import { mdiCheck, mdiBriefcaseVariantOutline, mdiCheckboxBlankCircle } from '@mdi/js'
import { avatarText, kFormatter } from '@core/utils/filter'
import { ref } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'
import { useRouter } from '@core/utils'
import UserBioEdit from './UserBioEdit.vue'
import useUsersList from '../../user-list/useUsersList'

export default {
  components: {
    UserBioEdit,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { router } = useRouter()
    const { resolveUserAccessVariant, resolveUserStatusVariant } = useUsersList()

    const isBioDialogOpen = ref(false)

    return {
      emit,
      resolveUserAccessVariant,
      resolveUserStatusVariant,
      avatarText,
      kFormatter,

      isBioDialogOpen,
      icons: {
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
      },
    }
  },
}
</script>
