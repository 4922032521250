import { mdiAccountMultiple, mdiAccountOutline, mdiCogOutline, mdiDnsOutline, mdiPencilOutline } from '@mdi/js'
import { ref, watch, onMounted } from '@vue/composition-api'
import { useActions, useState } from 'vuex-composition-helpers'

export default function useUsersList() {
  const userListTable = ref([])

  const tableColumns = [
    { text: 'user', value: 'fullName', class: 'text-uppercase' },
    { text: 'email', value: 'user.email', class: 'text-uppercase' },
    { text: 'role', value: 'access', sortable: false, class: 'text-uppercase' },
    { text: 'status', value: 'status', sortable: false, class: 'text-uppercase' },
    {
      text: 'actions',
      value: 'actions',
      align: 'center',
      sortable: false,
      class: 'text-uppercase',
    },
  ]

  const searchQuery = ref('')
  const totalUserListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })
  const userTotalLocal = ref([])
  const overview = ref([])

  const { getAllRecruiters, getAllRecruitersOverview } = useActions('company', [
    'getAllRecruiters',
    'getAllRecruitersOverview',
  ])
  const { recruiters } = useState('company', ['recruiters'])
  const fetchRecruitersOverview = async () => {
    const { data } = await getAllRecruitersOverview()
    overview.value = data
  }

  onMounted(async () => {
    await fetchRecruitersOverview()
  })

  // fetch data
  const fetchUsers = async () => {
    loading.value = true
    if (await getAllRecruiters({ search: searchQuery.value })) loading.value = false
  }

  const timer = ref(null)
  const delaySearchHandler = async () => {
    clearTimeout(timer.value)
    timer.value = setTimeout(async () => {
      await fetchUsers()
    }, 1000)
  }

  watch([options], async () => {
    await fetchUsers()
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserAccessVariant = (access) => {
    if (access === 'admin') return 'primary'
    if (access === 'recruiter') return 'success'
    if (access === 'expert') return 'info'
    if (access === 'suspended') return 'error'

    return 'primary'
  }

  const resolveUserAccessIcon = (access) => {
    if (access === 'expert') return mdiAccountOutline
    if (access === 'recruiter') return mdiCogOutline
    if (access === 'suspended') return mdiPencilOutline
    if (access === 'admin') return mdiDnsOutline

    return mdiAccountOutline
  }

  const resolveUserStatusVariant = (status) => {
    return status ? 'success' : 'warning'
  }

  const resolveUserTotalIcon = (title) => {
    if (title === 'total') return { icon: mdiAccountMultiple, color: 'secondary' }
    if (title === 'admins') return { icon: mdiDnsOutline, color: 'primary' }
    if (title === 'recruiters') return { icon: mdiCogOutline, color: 'success' }
    if (title === 'experts') return { icon: mdiAccountOutline, color: 'info' }

    return { icon: mdiAccountOutline, color: 'primary' }
  }

  return {
    userListTable,
    tableColumns,
    searchQuery,
    totalUserListTable,
    loading,
    options,
    userTotalLocal,
    overview,
    recruiters,
    fetchRecruitersOverview,
    fetchUsers,
    resolveUserAccessVariant,
    resolveUserAccessIcon,
    resolveUserStatusVariant,
    resolveUserTotalIcon,
    delaySearchHandler,
  }
}
