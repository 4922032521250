<template>
  <div class="user-tab-security">
    <!-- change password -->
    <v-card class="mb-7">
      <v-card-title v-t="'changePassword'" />

      <v-card-text>
        <v-alert color="warning" text>
          <p v-t="'ensureThatTheseRequirementsAreMet'" class="font-weight-semibold mb-1" />
          <p v-t="'minimumCharacterLongUppercaseAndSymbol'" class="text-sm mb-0" />
        </v-alert>

        <!-- form -->
        <v-form
          ref="changePasswordForm"
          v-model="valid"
          class="multi-col-validation"
          @submit.prevent="handleFormSubmit"
        >
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="newPassword"
                outlined
                dense
                :label="$t('newPassword')"
                :placeholder="$t('newPassword')"
                :type="isPasswordVisible ? 'text' : 'password'"
                :append-icon="isPasswordVisible ? icons.mdiEyeOutline : icons.mdiEyeOffOutline"
                :rules="[validators.required, validators.passwordValidator]"
                maxlength="128"
                @click:append="isPasswordVisible = !isPasswordVisible"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="confirmPassword"
                outlined
                dense
                :label="$t('confirmNewPassword')"
                :placeholder="$t('confirmNewPassword')"
                :type="isPasswordConfirmVisible ? 'text' : 'password'"
                :append-icon="isPasswordConfirmVisible ? icons.mdiEyeOutline : icons.mdiEyeOffOutline"
                :rules="[validators.required, validators.confirmPasswordValidator(confirmPassword, newPassword)]"
                maxlength="128"
                @click:append="isPasswordConfirmVisible = !isPasswordConfirmVisible"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-btn color="primary" :disabled="!valid" type="submit" :loading="loading">
                {{ $t('changePassword') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiSquareEditOutline, mdiDeleteOutline, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'
import { confirmPasswordValidator, passwordValidator, required } from '@core/utils/validation'

export default {
  setup() {
    const valid = ref(true)
    const changePasswordForm = ref(null)
    const loading = ref(false)
    const newPassword = ref('')
    const confirmPassword = ref('')
    const isPasswordVisible = ref(false)
    const isPasswordConfirmVisible = ref(false)

    const { changeRecruiterPassword } = useActions('company', ['changeRecruiterPassword'])

    const handleFormSubmit = async () => {
      const isFormValid = changePasswordForm.value.validate()
      if (!isFormValid) return
      loading.value = true
      await changeRecruiterPassword({ newPassword })
      loading.value = false
    }

    return {
      handleFormSubmit,

      validators: {
        required,
        confirmPasswordValidator,
        passwordValidator,
      },
      valid,
      changePasswordForm,
      loading,
      newPassword,
      confirmPassword,
      isPasswordVisible,
      isPasswordConfirmVisible,
      icons: {
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>
