<template>
  <div class="user-tab-overview">
    <!-- user project list -->
    <v-card class="mb-7">
      <v-card-title v-t="'jobList'" />

      <v-data-table :headers="tableColumnHeaders" :items="jobs" hide-default-footer>
        <template #[`header.title`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`header.candidatesAmount`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`header.stage`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`item.title`]="{ item }">
          <div class="d-flex flex-column ms-3">
            <router-link
              :to="{ name: 'apps-ats-job-view', params: { id: item.id } }"
              class="font-weight-semibold text-truncate cursor-pointer text-decoration-none"
            >
              {{ item.title }}
            </router-link>
            <span class="text-xs">{{ `${item.city}, ${item.country}` }}</span>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mdiDotsVertical } from '@mdi/js'

export default {
  props: {
    jobsData: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const tableColumnHeaders = [
      {
        text: 'title',
        value: 'title',
        class: 'text-uppercase',
      },
      { text: 'candidatesAmount', value: 'candidatesAmount', sortable: false, class: 'text-uppercase' },
      { text: 'status', value: 'stage', class: 'text-uppercase' },
    ]

    return {
      jobs: props.jobs,
      tableColumnHeaders,
      icons: {
        mdiDotsVertical,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.project-progress {
  min-width: 4rem;
}
</style>
